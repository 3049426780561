import { dev } from '$app/environment';
import * as Sentry from '@sentry/sveltekit';
import {
	breadcrumbsIntegration,
	browserProfilingIntegration,
	browserTracingIntegration,
	handleErrorWithSentry,
	replayIntegration
} from '@sentry/sveltekit';

// Only report Sentry errors in production.
!dev &&
	Sentry.init({
		dsn: 'https://6de0867a3a6fb7f94f3a0c897d27e9b3@o4504769389068288.ingest.us.sentry.io/4506936048091136',
		tracesSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,

		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,

		// If you don't want to use Session Replay, just remove the line below:
		integrations: [
			replayIntegration({
				maskAllText: false,
				maskAllInputs: false
			}),
			browserTracingIntegration(),
			browserProfilingIntegration(),
			breadcrumbsIntegration()
		],
		environment: dev ? 'development' : 'production'
	});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
